import React, { Fragment } from "react";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import { SearchIcon, MenuIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { navConfig } from "@/components/navigation/Navbar";
import * as Text from "@/components/text";

export default function PopoverMenu({ theme = "", className, ...rest }) {
  return (
    <Popover
      {...rest}
      className={classNames(className, "relative z-50 lg:hidden  ")}
    >
      {({ open }) => (
        <>
          <div className="relative z-10">
            <div className="">
              <Popover.Button
                className={classNames(
                  theme !== "light"
                    ? "text-white"
                    : open
                    ? "text-gray-900"
                    : "text-gray-600",
                  "border-none ring-0"
                )}
              >
                {/* <span>Solutions</span> */}
                {open ? null : (
                  <MenuIcon
                    className={classNames(
                      theme !== "light"
                        ? "h-7 w-7 text-white "
                        : open
                        ? "h-5 w-5 text-gray-900"
                        : "h-5 w-5 text-white",
                      "ml-2  group-hover:text-gray-700"
                    )}
                    // aria-hidden="true"
                  />
                )}
              </Popover.Button>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="fixed left-0 top-10 w-screen   transform shadow-lg">
              <div className="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                <nav
                  className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-2 sm:gap-x-8 sm:px-6 sm:py-12 lg:px-8 xl:pr-12"
                  aria-labelledby="solutions-heading"
                >
                  <div>
                    <ul className="mt-3 space-y-6 sm:mt-2">
                      {navConfig.map((e, i) => (
                        <li className="flex" key={i}>
                          <Link href={e.href} passHref>
                            <Text.Link label={e.label}></Text.Link>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </nav>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
