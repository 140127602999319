import React from "react";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";

export default function BlockingOverlay({
  topOffset = "t-0",
  onEnter,
  onClick,
  z = "z-10",
  transparent = false,
}) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <motion.div
      onMouseEnter={onEnter ? () => onEnter() : () => {}}
      onClick={onClick ? () => onClick() : () => {}}
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.8 }}
      exit={{ opacity: 0 }}
      className={twMerge(
        "fixed right-0 top-0 h-screen w-screen bg-neutral-1000/80",
        transparent && "bg-transparent",
        z,
        topOffset
      )}
    />
  );
}
